import React from 'react'
import AdminTemplate from '../components/core/Auth/AdminTemplate'
const AdminSignup = () => {
  return (
    <div>
      <AdminTemplate
        formtype='signup'
      />
    </div>
  )
}

export default AdminSignup
