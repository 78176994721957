import React from 'react'
import AdminTemplate from '../components/core/Auth/AdminTemplate'
const AdminLogin = () => {
  return (
    <div >
      <AdminTemplate
        formtype='login'
      />
    </div>
  )
}


export default AdminLogin
